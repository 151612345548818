<template>
    <div class="page">
        <m-header :title="title" />

        <div class="company-list">
            <div
                class="company-item backInUp"
                v-for="(item, index) in renderList"
                :key="item.companyName"
                @click="jumpUrl(item.Link)"
                :style="getStyle(index)"
            >
                <div class="inner-box">
                    <div class="logo-img">
                        <img v-lazy="getLogo(item)" />
                    </div>
                    <div class="info-box">{{ item.CompanyName }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mHeader from "@/components/Header.m.vue";
import { jump } from "@/utils/utils.js";
import { list } from ".";
export default {
    data() {
        return {
            title: "",
            active: 1,
            list,
            renderList: [],
        };
    },
    created() {
        const type = Number(this.$route.params.type);
        const currList = this.list.find((item) => item.type === type);
        this.active = type;
        this.title = currList.name;
        this.renderList = currList.data;
    },
    methods: {
        getLogo(item) {
            const id = item.id >= 10 ? item.id : "0" + item.id;
            return require("@/assets/images/logo/" + id + ".png");
        },
        jumpUrl: (url) => {
            jump(url, "_self");
        },
        getStyle(index) {
            return {
                "animation-delay": index * 0.02 + "s",
            };
        },
    },
    components: {
        mHeader,
    },
};
</script>
<style lang="less" scoped>
.page {
    width: 100%;
    height: 100%;
    font-size: 32px;
    background-image: url("~@/assets/images/other/m/bg.png");
    background-size: 100% 100%;
    overflow: hidden;
}

.company-list {
    width: 100%;
    height: calc(100vh - 30px - 100px);
    display: flex;
    flex-wrap: wrap;
    padding: 45px 30px 0;
    overflow: auto;
}

.company-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 216px;
    border: 1px solid #003f93;
    margin-right: 21px;
    margin-bottom: 30px;
    background-color: #002352;

    &:nth-of-type(3n) {
        margin-right: 0;
    }
}

.inner-box {
    width: 206px;
    height: calc(100% - 10px);
    background-color: #002352;
    border: 1px solid #003986;

    .logo-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        background-color: #fff;

        img {
            width: 85%;
        }
    }

    .info-box {
        color: #fff;
        font-size: 22px;
        line-height: 24px;
        padding: 18px 16px;
        text-align: center;
    }
}
</style>
