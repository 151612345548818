<template>
    <div class="container">
        <div class="main" ref="main" :style="mainStyle">
            <Top
                :title="
                    require('@/assets/images/other/title-' +
                        (Number(active) + 1) +
                        '.png')
                "
            />

            <div class="tabs">
                <div
                    class="tab-item"
                    :class="[
                        { active: active === item.type },
                        'tab-animate-' + (index + 1),
                    ]"
                    v-for="(item, index) in tabs"
                    :key="item.type"
                    v-html="item.name"
                    @click="onTabClick(item)"
                ></div>
            </div>

            <div class="list-bg backInUp">
                <transition name="el-fade-in" mode="out-in" appear>
                    <div class="list" :key="active + pagination.page">
                        <div
                            class="item"
                            v-for="item in renderList"
                            :key="item.id"
                            @click="jumpUrl(item.Link)"
                        >
                            <div class="inner-box">
                                <div class="logo-box">
                                    <img :src="getLogo(item)" />
                                </div>

                                <div class="info-box">
                                    {{ item.CompanyName }}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="pagination-box backInUp">
                <Pagination
                    :current-page="pagination.page"
                    :page-size="pagination.size"
                    :total="pagination.total"
                    @current-change="changePage"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Top from "@/components/Top.vue";
import Pagination from "@/components/Pagination.vue";
import { jump, imgsPreloader } from "@/utils/utils.js";
import { list } from "./index";
export default {
    data() {
        return {
            active: 1,
            list: [],
            renderList: [],
            tabs: list,
            pagination: {
                total: 0,
                page: 1,
                size: 8,
            },
            mainStyle: {},
        };
    },
    created() {
        const type = Number(this.$route.params.type);
        const index = this.tabs.findIndex((item) => item.type === type);
        this.active = type;
        this.list = this.tabs[index].data;
        this.pagination.total = this.list.length;
        this.setList();
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        async setList() {
            const { page, size } = this.pagination;
            this.renderList = [];
            const list = this.list.slice((page - 1) * size, page * size);
            await this.loadImage(list);
            this.renderList = list;
        },
        onResize() {
            const mainRef = this.$refs.main;
            const windowHeight = window.innerHeight;
            const mainHeight = mainRef.clientHeight;
            const scale = windowHeight / (mainHeight + 49);
            this.mainStyle = {
                transform: `scale(${scale > 1 ? 1 : scale})`,
                "transform-origin": "top center",
            };
        },
        async loadImage(list) {
            const urls = list.map(this.getLogo);
            await imgsPreloader(urls);
        },
        onTabClick({ type, data }) {
            this.active = type;
            this.list = data;
            this.pagination.total = data.length;
            this.pagination.page = 1;
            this.setList();
        },
        changePage(page) {
            this.pagination.page = page;
            this.setList();
        },
        getLogo(item) {
            const id = item.id >= 10 ? item.id : "0" + item.id;
            return require("@/assets/images/logo/" + id + ".png");
        },
        jumpUrl: (url) => {
            jump(url);
        },
    },
    components: {
        Top,
        Pagination,
    },
};
</script>
<style lang="less" scoped>
.container {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/other/bg.png");
    background-size: 100% 100%;
    overflow: hidden;
}

.main {
    position: absolute;
    left: 300px;
    right: 300px;
    padding-top: 150px;

    @media screen and (max-width: 1440px) {
        padding-top: 100px;
    }
}

.tabs {
    position: relative;
    display: flex;
    margin-top: 57px;
    height: 60px;

    .tab-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 195px;
        height: 60px;
        border: 2px solid #012f6d;
        border-radius: 6px;
        color: #46c0ff;
        font-size: 18px;
        line-height: 20px;
        // margin-right: 30px;
        cursor: pointer;
        user-select: none;

        &:last-of-type {
            margin-right: 0;
        }

        &.active {
            background-image: url("~@/assets/images/other/tab-bg.png");
            color: #fff;
        }
    }
}
.list-bg {
    margin-top: 32px;
    width: 100%;
    height: 587px;
    background-image: url("~@/assets/images/other/list-bg.png");
    background-size: 100% 100%;
    padding-top: 46px;
    padding-left: 64px;
    overflow: hidden;

    .list {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 260px;
            height: 230px;
            border: 1px solid #012f6d;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 50px;
            margin-bottom: 30px;

            &:nth-of-type(4n) {
                margin-right: 0;
            }

            .inner-box {
                width: 250px;
                height: 220px;
                border: 1px solid #012f6d;

                .logo-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 140px;
                    background-color: #fff;

                    img {
                        width: 80%;
                    }
                }

                .info-box {
                    height: 80px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    padding: 0 20px;
                }
            }

            &:hover .info-box {
                color: #46c0ff;
            }
        }
    }
}

.pagination-box {
    margin-top: 27px;
    display: flex;
    justify-content: center;
}

.keyframesFunction (@name,@content) {
    @keyframes @name {
        @content();
    }
}

each(range(6),{
    .keyframesFunction(~'tab-animate-@{value}', {
        from {
            left: 100%;
        }
        to {
            left: 195px * (@value - 1) + 30px * (@value - 1);
            opacity: 1;
        }
    });

    .tab-animate-@{value} {
        opacity: 0;
        position: absolute;
    }

});

@animate-list: 0, 0.2, 0.4, 0.6, 0.8, 1;
each(@animate-list, {
    .tab-animate-@{index} {
        animation: ~'tab-animate-@{index}' 1s;
        animation-fill-mode: forwards;
        animation-delay: ~'@{value}s';
    }
});
</style>
