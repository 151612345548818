<template>
    <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            v-bind="$attrs"
            v-on="$listeners"
        >
        </el-pagination>
    </div>
</template>
<script>
import { Pagination } from "element-ui";
export default {
    components: { [Pagination.name]: Pagination },
};
</script>
<style lang="less">
.pagination {
    user-select: none;

    .btn-prev,
    .btn-next {
        width: 80px;
        background-color: transparent !important;
        border: 1px solid #334559;
        border-radius: 0 !important;

        span {
            font-size: 14px;
            color: #f2f3f5;
        }

        &:not(:disabled):hover {
            border-color: #f2f3f5;
        }
    }

    .el-pager {
        .number,
        .more {
            background-color: transparent !important;
            border: 1px solid #334559;
            color: #f2f3f5 !important;
            font-size: 14px;
            border-radius: 0 !important;

            &.active,
            &:hover {
                border-color: #f2f3f5;
            }
        }
    }
}
</style>
