import BiologicalMedicine from "@/data/BiologicalMedicine.js"; // 海洋生物与医药
import EcosystemConservation from "@/data/EcosystemConservation.js"; // 海洋旅游和文化 2
import ElectronicInformation from "@/data/ElectronicInformation.js"; // 海洋电子信息
import HighEndDevices from "@/data/HighEndDevices.js"; // 海洋工程和环保
import MarineTourismCulture from "@/data/MarineTourismCulture.js"; // 海洋旅游与文化 1
import OceanShipping from "@/data/OceanShipping.js"; // 海洋港口与航运
import ResourceDevelopment from "@/data/ResourceDevelopment.js"; // 海洋资源开发

export const list = Object.freeze([
    {
        type: 1,
        name: "海洋港口与航运",
        data: OceanShipping,
    },
    {
        type: 2,
        name: `海洋油气与<br />矿产资源开发`,
        data: ResourceDevelopment,
    },
    {
        type: 3,
        name: `海洋电子信息`,
        data: ElectronicInformation,
    },
    {
        type: 4,
        name: `海洋工程和环保`,
        data: HighEndDevices,
    },
    {
        type: 5,
        name: `海洋生物与医药`,
        data: BiologicalMedicine,
    },
    {
        type: 6,
        name: `海洋旅游与文化`,
        data: [...MarineTourismCulture, ...EcosystemConservation],
    },
]);
